import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { BigNumber, ethers } from "ethers";
import { NFTCollectible__factory } from './typechain-types/factories/contracts/NFTCollectible__factory'
import type { NFTCollectible } from './typechain-types/contracts/NFTCollectible';
import logo from './metamask.svg';
import axios from 'axios';
//import { TypedListener } from '@ethersproject/contracts';
// import dotenv from "dotenv";

// dotenv.config();
// const { API_URL, PRIVATE_KEY } = process.env;

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const contractAddress     = "0x1041a0E9936eFd11f461463caF1A732c08c5472b";
const intermediaryAddress = "0xB58fcC7d47DF4964f4787c594da0EE9D6fF8437c";
const local_contractAddress     = "0x5FbDB2315678afecb367f032d93F642f64180aa3";
const local_intermediaryAddress = "0x90F79bf6EB2c4f870365E785982E1f101E93b906";
const API_SERVER = "http://api-aro-dev.avataroad.com/";
const ACCESST_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTm8iOjE5LCJpYXQiOjE2OTY0MjI4MTksImV4cCI6MTY5NjQyNjQxOX0.OAeD4SL2uOgr2Ak1ak3X2LkkW1B68J2zOuRy_0sPtes";
const waitingTime = 3000;

type IconColor = "disabled" | "success";
interface IWallet {
  iconColor: IconColor;
  connectedWallet: string;
  contractAddress: string;
  contractSymbol: string;
  contractBaseTokenURI: string;
  contractOwnerAddress: string;
  // contractPrice: string;
  isOwner: boolean;
}

interface IService {
  account: string;
  ethProvider?: ethers.providers.Web3Provider,
  contract?: NFTCollectible;
  currentBalance: number;
  ethBalance: string;
  mintAmount: number;
  // tokenIds: number[];
  toAddress: string;
}

function EthersDemo() {
  const [state, setState] = React.useState<IWallet>({
    iconColor: "disabled",
    connectedWallet: "",
    contractSymbol: "",
    contractAddress: "",
    contractBaseTokenURI: "",
    contractOwnerAddress: "",
    // contractPrice: "",
    isOwner: false
  });

  const [nftCollection, setNFTCollection] = React.useState<string[]>([]);    // 이미지
  const [nftIds, setNFTIds] = React.useState<BigNumber[]>([]);               // 본인의 토큰 확인용
  const [srcIds, setSrcIds] = React.useState<string[]>([]);                  // 입력 토큰용
  const [srcAddresses, setSrcAddresses] = React.useState<string[]>([]);          // approved 주소 확인용
  const [connect, setConnect] = React.useState<NFTCollectible>(); 
  const [contractIntermediary, setContractIntermediary] = React.useState<NFTCollectible | null>(null);
  const [contractBalance, setContractBalance] = React.useState<BigNumber>(ethers.BigNumber.from(0));
  const [receiveAddress, setReceiveAddress] = React.useState<string>("");          // approved 주소 확인용
  const [price, setPrice] = React.useState<BigNumber>(ethers.utils.parseEther("0"));          // 토큰1개당 가격
  const [step, setStep] = React.useState<number>(0);          // 중개이전 트랜잭션용 성공단계

  const [service, setService] = React.useState<IService>({
    account: "",
    currentBalance: 0,
    ethBalance: "",
    mintAmount: 0,
    // tokenIds: [],
    toAddress: ""
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setService({...service, mintAmount: 0, toAddress: intermediaryAddress});
  }
  const handleClose = () => setOpen(false);

  const [openApprove, setOpenApprove] = React.useState(false);
  const handleOpenApprove = () => {
    setOpenApprove(true);
    setService({...service, toAddress: ""});
    setSrcIds([]);
  }
  const handleCloseApprove = () => setOpenApprove(false);

  const [openCheck, setOpenCheck] = React.useState(false);
  const handleOpenCheck = () => {
    setOpenCheck(true);
    setService({...service, toAddress: ""});
    setSrcIds([]);
    setSrcAddresses([]);
  }
  const handleCloseCheck = () => setOpenCheck(false);

  const [openTransfer, setOpenTransfer] = React.useState(false);
  const handleOpenTransfer = () => {
    setOpenTransfer(true);
    setService({...service, toAddress: ""});
    setSrcIds([]);
  }
  const handleCloseTransfer = () => setOpenTransfer(false);

  const [openBurn, setOpenBurn] = React.useState(false);
  const handleOpenBurn = () => {
    setOpenBurn(true);
    // setService({...service, toAddress: ""});
    setSrcIds([]);
  }
  const handleCloseBurn = () => setOpenBurn(false);

  const [openWithdrawal, setOpenWithdrawal] = React.useState(false);
  const handleOpenWithdrawal = () => setOpenWithdrawal(true);
  const handleCloseWithdrawal = () => setOpenWithdrawal(false);

  const connectWallet = async () => {
    try {
      console.log("connect wallet");
    //   const { ethereum } = window;
      const ethereum: any = (window as any).ethereum;

      if (!ethereum) {
        alert("Please install MetaMask!");
        return;
      }

      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log("Connected", accounts[0]);

      const provider = new ethers.providers.Web3Provider(ethereum);
      const contract = NFTCollectible__factory.connect(contractAddress, provider.getSigner());
      setConnect(contract);
      setReceiveAddress(accounts[0]);
      //const contract = new ethers.Contract(contractAddress, NFTCollectible__factory.abi, signer) as NFTCollectible;
      const ownerAddress = await contract.owner();
      console.log("Contract Address", contract.address);
      console.log("ownerAddress", ownerAddress);
      const symbol = await contract.symbol();
      const baseTokenURI = await contract.baseTokenURI();
      // const balance = await (await contract.balanceOf(accounts[0])).toNumber();
      const ethBalance = ethers.utils.formatEther(await provider.getBalance(accounts[0]));
      const balance = parseInt(ethBalance);
      const isOwner = (ownerAddress.toLowerCase() === accounts[0].toLowerCase());
      // const price = ethers.utils.formatEther(await contract.PRICE());
      console.log("balance", balance);
      console.log("ethBalance", ethBalance);
      console.log("isOwner", isOwner);
      // console.log("price", price);
      const conBalance  = await contract.getContractBalance();
      console.log("=== Contract Balance : "+conBalance);
      setContractBalance(conBalance);

      setState({
        iconColor: "success",
        connectedWallet: accounts[0],
        contractSymbol: symbol,
        contractAddress: contract.address,
        contractBaseTokenURI: baseTokenURI,
        contractOwnerAddress: ownerAddress,
        // contractPrice: `${price} ETH`,
        isOwner: isOwner
      });      

      setService({
        account: accounts[0],
        contract: contract,
        currentBalance: balance,
        ethBalance: `${ethBalance} ETH`,
        mintAmount: 0,
        ethProvider: provider,
        // tokenIds: [],
        toAddress: ""
      });
      //loadContractBalance();
      setSrcIds([]);
      setPrice(ethers.utils.parseEther("0"));
      console.log("Connected", accounts[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const loadContractBalance = async () => {
    try {
      console.log("load contract balance");
      const contract = service.contract!;

      const conBalance  = await contract.getContractBalance();
      console.log("=== Contract Balance : "+conBalance);
      setContractBalance(conBalance);

    } catch (error) {
      console.log(error);
    }
  };

  const loadNFTCollection = async () => {
    try {
      console.log("load NFT collection");
      let baseURI: string = state.contractBaseTokenURI;
      // baseURI = baseURI.replace("ipfs://", "https://gateway.pinata.cloud/ipfs/");
      // setNFTCollection(
      //   [
      //     `${baseURI}0001.svg`,
      //     `${baseURI}0002.svg`,
      //     `${baseURI}0003.svg`,
      //     `${baseURI}0004.svg`,
      //   ]);
        baseURI = "http://api-aro-dev.avataroad.com/thumbnail";
        // setNFTCollection(
        //   [
        //     "http://api-aro-dev.avataroad.com/thumbnail/202030905/1693894497699.png",
        //     "http://api-aro-dev.avataroad.com/thumbnail/202030905/1693894587622.png",
        //     "http://api-aro-dev.avataroad.com/thumbnail/202030907/1694046807204.png",
        //   ]);
        setNFTCollection(
          [
            `${baseURI}/20230905/1693894497699.png`,
            `${baseURI}/20230905/1693894587622.png`,
            `${baseURI}/20230907/1694046807204.png`,
          ]);
    } catch (error) {
      console.log(error);
    }
  };

  const loadNFTIDs = async () => {
    try {
      console.log("load NFT IDs");
      // const provider = new ethers.JsonRpcProvider('YOUR_RPC_URL_HERE'); // Ethereum RPC URL 입력
      // const contract = new ethers.Contract(contractAddress, ['balanceOf', 'tokenOfOwnerByIndex'], provider);

      // const provider = new ethers.providers.Web3Provider(ethereum);
      // const contract = NFTCollectible__factory.connect(contractAddress, provider.getSigner());
      const contract = service.contract!;
      // const ownerAddress = state.contractOwnerAddress;
      const ownerAddress = state.connectedWallet;

      const tokenIds  = await contract.tokensOfOwner(ownerAddress);
      setNFTIds(tokenIds);

    } catch (error) {
      console.log(error);
    }
  };

  /*
  const mintNFTs = async () => {
    try {
          //API 서버 call하기 - Mint 결과주기(string[])

          // 이더스캔 API URL을 정의합니다.
          const etherscanApiUrl = "https://api-sepolia.etherscan.io/";
          // const etherscanApiUrl = "https://sepolia.etherscan.io/";
          console.log("0")
          // https://sepolia.etherscan.io/tx/0x4f63a271c3be01f5f6c57ae47bdf0461f6c03ce2225961453598a2d53e8aaf53
          // 트랜잭션 해시를 입력합니다.
          const transactionHash = '0x4f63a271c3be01f5f6c57ae47bdf0461f6c03ce2225961453598a2d53e8aaf53';
          const blockNumber = 4639391;
          // const filter = {
          //   blockNumber: blockNumber,
          //   topics: ["0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4e117389fa46aca998bbc"],
          // };
          const filter = {
            blockNumber: blockNumber,
            topic0: "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
          };

          const apiKey = 'Z1HVF54RBEIN2PJWVWPVI6MYMBEDTVFRNW';
          const url = `${etherscanApiUrl}tx/${transactionHash}`;
          // const url = `${etherscanApiUrl}api?module=proxy&action=eth_getTransactionByHash&txhash=${transactionHash}&apikey=${apiKey}`;
          // const url = `${etherscanApiUrl}api?module=logs&action=getLogs&address=0x1041a0E9936eFd11f461463caF1A732c08c5472b&fromBlock=0x46ca9f&toBlock=0x46ca9f&page=1&offset=5`;
          // const url = `${etherscanApiUrl}api?module=logs&action=getLogs&filter=${JSON.stringify(filter)}&apikey=${apiKey}`;
          // const url = `${etherscanApiUrl}api?module=logs&action=getLogs&address=0x1041a0E9936eFd11f461463caF1A732c08c5472b&fromBlock=0x46ca9f&toBlock=0x46ca9&topic0=0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef&page=1&offset=5`;

          // 이더스캔 API에 요청을 보냅니다.
          const response = axios.get(url)
                .then((response) => {
                  // 응답을 처리합니다.
                  console.log(" response : "+JSON.stringify(response));
                  const data = response.data;
                  console.log(" data : "+JSON.stringify(data));
                  // 토큰 ID를 찾습니다.
                  // const tokenIds = data.result.map((log) => log.topics[2]);

                  // // 토큰 ID를 출력합니다.
                  // console.log(tokenIds);
                  //로그를 출력합니다.
                  // data.result.forEach((log) => {
                  //   console.log("토큰 소유자 주소:", log.topics[0]);
                  //   console.log("토큰 ID:", log.topics[1]);
                  //   if (log.topics[2].length === 64) {
                  //     const tokenId = parseInt(log.topics[2], 16);
                  //     console.log("토큰 ID:", tokenId);
                  //     // 토큰 ID를 사용합니다.
                  //   } else {
                  //     console.log("NOT 토큰 ID:");
                  //   }
                    
                  // });
                                    // 트랜잭션이 성공적으로 실행되었는지 확인합니다.
                  // console.log(data.result.status);
                  // if (data.result.status !== 1) {
                  //   console.error("트랜잭션이 실패했습니다.");
                  //   return;
                  // }

                  // // ERC-721 토큰 트랜잭션의 정보를 찾습니다.
                  // console.log("logs : "+data.result.logs);
                  // const nft = data.result.logs.find((log) => log.topics[0] === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4e117389fa46aca998bbc");

                  // if (nft) {
                  //   console.log("토큰 소유자 주소:", nft.topics[1]);
                  //   console.log("토큰 ID:", nft.topics[2]);
                  // }
                })
                .catch((error) => {
                  console.error(error);
                });

          // // // 이더스캔 HTTP 프로바이더를 생성합니다.
          // const provider = new ethers.providers.EtherscanProvider(etherscanApiUrl);
          // console.log("1")
          // // // 트랜잭션 정보를 가져옵니다.
          // const response = await provider.getTransaction(transactionHash);
          // console.log("2")
          // // 토큰 ID를 출력합니다.
          // console.log(response);


          // // 토큰 ID를 가져옵니다.
          // // const tokenId = response.data;

          //  const headers = {
          //   // "X-API-KEY": apiKey,
          // };
          // axios.get(etherscanApiUrl+'tx/'+transactionHash, { headers }).then((response) => {
          //   console.log(response.data);
            // 트랜잭션을 반복합니다.
            // for (const event of response.data) {
            //   // 이벤트가 mint/transfer인지 확인합니다.
            //   if (event.event_type === "mint" || event.event_type === "transfer") {
            //     // 트랜잭션 정보를 출력합니다.
            //     console.log(event);
            //   }
            // }
          // });  


          // const url = `https://api.opensea.io/v1/events?chain=sepolia&transaction_hash=${transactionHash}&event_type=mint,transfer`;
          // const url = `https://sepolia.etherscan.io/tx/0x4f63a271c3be01f5f6c57ae47bdf0461f6c03ce2225961453598a2d53e8aaf53`;
          // const headers = {
          //   // "X-API-KEY": apiKey,
          // };
          
          // axios.get(url, { headers }).then((response) => {
          //   console.log(response.data);
          //   // 트랜잭션을 반복합니다.
          //   for (const event of response.data) {
          //     // 이벤트가 mint/transfer인지 확인합니다.
          //     if (event.event_type === "mint" || event.event_type === "transfer") {
          //       // 트랜잭션 정보를 출력합니다.
          //       console.log(event);
          //     }
          //   }
          // });  
        

    } catch (error) {
      console.log(error);
      alert("mintNFTs error : "+error);
    }
  };
*/

  const mintNFTs = async () => {
    try {
      console.log("mint NFTs");
      const address = service.account;
      // console.log("============= address : "+address);
      const amount = service.mintAmount!;
      const contract = service.contract!;
      // const price = await contract.PRICE();
      // const ethValue = price.mul(BigNumber.from(amount));
      const signer = service.ethProvider!.getSigner();
      const to = service.toAddress!;
      // const ownerAddress = state.connectedWallet;

      let eventListener: any;
      // 이벤트
      const mintFilter = contract.connect(signer!).filters.NewMintToken();
      console.log('Waiting for Mint Event...');

      // mintNFTs(중개인 지갑주소, 민트 갯수, asset_no, product_no, { value: ethValue });
      //let txn = await contract.connect(signer!).mintNFTs(to, amount, 33, 10, { value: ethValue });
      // mintNFTs(중개인 지갑주소, 민트 갯수, asset_no, product_no);
      const assetNo = 33;
      const productNo = 10;
      let txn = await contract.connect(signer!).mintNFTs(to, amount, assetNo, productNo);
      const result = await txn.wait();
      let Ids: string[] = []; 

      // let eventFlag = false;
      let tokenIdAry;
      // contract.on('NewMintToken', (event) => handleEvent('Mint Token', event.args));
      eventListener = contract.connect(signer!).on(mintFilter, async (count, assetNames, createdTime, event) => {
        console.log('Mint TransactionHash :'+txn.hash);
        console.log('Mint Event:');
        console.log('count:', count.toNumber());
        console.log('assetNames:', assetNames);
        tokenIdAry = assetNames;
        console.log('createdTime:', createdTime);

        // console.log('eventFlag :'+eventFlag);
        // if(!eventFlag) {
          // API 서버 call하기 - Mint 결과주기(string[])
          // const sendData = {
          //   contractId: contractAddress,
          //   tokenIdAry
          // };
          // console.log("sendData", sendData);

          // const ret = await axios({
          //   method:'post',
          //   url: API_SERVER + 'nft/mint',
          //   data: sendData,
          //   headers: {
          //     'Authorization': `Bearer ${ACCESST_TOKEN}`,
          //   },
          // });

          // 이벤트 처리기 완료
          // eventFlag = true;
          // console.log("Mint API called!");
          // console.log('eventFlag : '+eventFlag);
          // 이벤트 처리기 해제
          contract.connect(signer!).off(mintFilter, eventListener);
        // }

      });

      // 3초 후에 이벤트 수신 중지
      setTimeout(async () => {
        console.log('No Mint Event received within 3 seconds.');
        // if(!eventFlag && result.status === 1) {
        if(result.status === 1) {
          // console.log('Mint TransactionHash :'+txn.hash);
          console.log('transaction events', result.logs);
          let i = 0;
          for(i; i < amount; i++){
            console.log('transaction events : ', parseInt(result.logs[i*2].topics[3], 16));         
            Ids.push(assetNo+"_"+productNo+"_"+parseInt(result.logs[i*2].topics[3], 16));
          }
          console.log("Ids : "+Ids);
          // API 서버 call하기 - Mint 결과주기(string[])
          // const sendData = {
          //   contractId: contractAddress,
          //   tokenIdAry
          // };
          // console.log("sendData", sendData);

          // const ret = await axios({
          //   method:'post',
          //   url: API_SERVER + 'nft/mint',
          //   data: sendData,
          //   headers: {
          //     'Authorization': `Bearer ${ACCESST_TOKEN}`,
          //   },
          // });
          console.log("=== Mint API called!"); 
        }
         // 이벤트 처리기 해제
        contract.connect(signer!).off(mintFilter, eventListener);
      }, waitingTime);

      const balance = await contract.balanceOf(address);
      setService({...service, currentBalance: balance.toNumber(), mintAmount: 0});

    } catch (error) {
      console.log(error);
      alert("mintNFTs error : "+error);
    }
  };

  const approveNFTs = async () => {
    try {
      console.log("approve NFTs");
      const address = service.account;
      // console.log("============= address : "+address);
      const contract = service.contract!;
      const signer = service.ethProvider!.getSigner();
      const to = service.toAddress!;
      // const tAmount = service.transferMintAmount!;
      // const ethTValue = price.mul(BigNumber.from(tAmount));
      // console.log("=========== : "+transferIds);
      let Ids: number[] = []; 
      for (let i = 0; i < srcIds.length; i++) {
          // console.log("========= : " + srcIds[i]+ ", type : " + typeof(srcIds[i]));
          const Id = srcIds[i].replace(/,/g, '');
          // console.log("["+Id+"] : "+ ", type : " + typeof(Id)+ ", : "+Number(Id)+ ", 길이 : "+Id.length);
          if(Id.length > 0)
            Ids.push(Number(Id));
      }
      // console.log("======= to : " + to +", Ids : " + Ids);
      let txn = await contract.connect(signer!).approveNFTs(to, Ids);
      await txn.wait();
      const balance = await contract.balanceOf(address);
      // setService({ ...service, tokenIds: tokenIdsArray });
      setService({...service, currentBalance: balance.toNumber(), mintAmount: 0});
    } catch (error) {
      console.log(error);
    }
  };

  const approvedCheckNFTs = async () => {
    try {
      console.log("approvedCheck NFTs");
      const address = service.account;
      // console.log("============= address : "+address);
      const contract = service.contract!;
      const signer = service.ethProvider!.getSigner();
      const to = service.toAddress!;
      // const tAmount = service.transferMintAmount!;
      // const ethTValue = price.mul(BigNumber.from(tAmount));
      // console.log("=========== : "+transferIds);
      let Ids: number[] = []; 
      for (let i = 0; i < srcIds.length; i++) {
          // console.log("========= : " + srcIds[i]+ ", type : " + typeof(srcIds[i]));
          const Id = srcIds[i].replace(/,/g, '');
          // console.log("["+Id+"] : "+ ", type : " + typeof(Id)+ ", : "+Number(Id)+ ", 길이 : "+Id.length);
          if(Id.length > 0)
            Ids.push(Number(Id));
      }
      // console.log("======= to : " + to +", Ids : " + Ids);
      const approvedAddresses = await contract.connect(signer!).getApprovedAddresses(Ids);
      setSrcAddresses(approvedAddresses);
      for (let i = 0; i < Ids.length; i++) {
          const tokenId = Ids[i];
          const approvedAddressForToken = approvedAddresses[i];

          if (approvedAddressForToken  !== '0x0000000000000000000000000000000000000000') {
              console.log(`Token ${tokenId} has an approved address: ${approvedAddressForToken }`);
          } else {
              console.log(`Token ${tokenId} does not have an approved address.`);
          }
      }
      const balance = await contract.balanceOf(address);
      // setService({ ...service, tokenIds: tokenIdsArray });
      setService({...service, currentBalance: balance.toNumber(), mintAmount: 0});
    } catch (error) {
      console.log(error);
    }
  };

  const transferNFTs = async () => {
    try {
      console.log("transfer NFTs");
      const address = service.account;
      // console.log("============= address : "+address);
      const contract = service.contract!;
      const signer = service.ethProvider!.getSigner();
      const to = service.toAddress!;
      // const tAmount = service.transferMintAmount!;
      // const ethTValue = price.mul(BigNumber.from(tAmount));
      // console.log("=========== : "+transferIds);
      let Ids: number[] = []; 
      for (let i = 0; i < srcIds.length; i++) {
          // console.log("========= : " + srcIds[i]+ ", type : " + typeof(srcIds[i]));
          const Id = srcIds[i].replace(/,/g, '');
          // console.log("["+Id+"] : "+ ", type : " + typeof(Id)+ ", : "+Number(Id)+ ", 길이 : "+Id.length);
          if(Id.length > 0)
            Ids.push(Number(Id));
      }
      // console.log("======= : "+Ids);
      let txn = await contract.connect(signer!).transferNFTs(to, Ids);
      await txn.wait();
      const balance = await contract.balanceOf(address);
      // setService({ ...service, tokenIds: tokenIdsArray });
      setService({...service, currentBalance: balance.toNumber(), mintAmount: 0});
    } catch (error) {
      console.log(error);
    }
  };

  const intermediaryNFTs = async () => {

    console.log("intermediary NFTs");
    const address = service.account;
    // console.log("============= address : "+address);
    const contract = service.contract!;
    const signer = service.ethProvider!.getSigner();
    const to = service.toAddress!;
    // const price = await contract.PRICE();
    // const tAmount = service.transferMintAmount!;
    // console.log("=========== srcIds : "+srcIds);
    // console.log("=========== srcIds : "+JSON.stringify(srcIds));
    let sendData;
    let Ids: number[] = []; 
    let strIds: string[] = [];  
    try {
      for (let i = 0; i < srcIds.length; i++) {
          // console.log("========= : " + srcIds[i]+ ", type : " + typeof(srcIds[i]));
          const Id = srcIds[i].replace(/,/g, '');
          //console.log("["+Id+"] : "+ ", type : " + typeof(Id)+ ", : "+Number(Id)+ ", 길이 : "+Id.length);
          if(Id.length > 0){
            Ids.push(Number(Id));
            strIds.push(Id);
          }
      }
      // console.log("======= : "+to);
      // console.log("======= : "+Ids);
      //console.log("========= srcIds : [" + srcIds+"]");
      const amount = price.mul(Ids.length);
      const ethValue = amount;

      // 중개시스템 지갑으로 contract 연결
      setStep(0);
      if(contractIntermediary != null) {

        let transferTListener: any;
        let transferEListener: any;
        let approveListener: any;

        const previousOwner = await contract.connect(signer!).getTokenOwner(Ids);
        console.log("previousOwner : "+previousOwner);
        // intermediaryNFTs(구매자의 지갑주소, 구매할 토큰 배열);
        const tx = await contractIntermediary.intermediaryNFTs(receiveAddress, Ids);
        const ret = await tx.wait();

        // 이전 소유자에게서 현재 사용자에게 NFT가 전송되었다는 이벤트 수신
        const transferTFilter = contractIntermediary.filters.NewTransferToken();
        transferTListener = contractIntermediary.on(transferTFilter,  async (from, to, tokeIds, event) => {
          console.log('Transfer Token Event:');
          console.log('From:', from);
          console.log('To:', to);
          for (let i = 0; i < tokeIds.length; i++) {
            //console.log('tokeIds:', tokeIds[i].toNumber());
          }
          // Event 처리가 완료되면 리스너 중지
          contractIntermediary.off(transferTFilter, transferTListener);
        });
          // 3초 후에 이벤트 수신 중지
          setTimeout(async () => {
            console.log('No Transfer Token Event received within 3 seconds.');
            // if(!eventFlag && result.status === 1) {
            if(ret.status === 1) {
              setStep(1);
            }
            // 이벤트 처리기 해제
            contractIntermediary.off(transferTFilter, transferTListener);
          }, waitingTime);

        //let txn = await contract.connect(signer!).sendEther(to, amount, { value: ethValue });
        // let txn = await contract.connect(signer!).intermediaryNFTs(to, Ids, amountInWei, { value: ethValue });
        // intermediaryEther(구매할 토큰 배열, 가격);
        //console.log("==============  : "+contract.connect(signer!).address);
        
        const txn = await contract.connect(signer!).intermediaryEther(previousOwner,Ids, amount, intermediaryAddress, { value: ethValue });
        const result = await txn.wait();
        // if (result.status === 1) {
        //   console.log('transaction events', result.logs);
        // }

        // 현재 사용자가 NFT 중개소 계약에 이더를 전송했다는 이벤트 수신
        const transferEFilter = contract.connect(signer!).filters.NewTransferEther();
        transferEListener = contract.connect(signer!).on(transferEFilter, (from, to, amount, event) => {
          console.log('Transfer Ether Event:');
          console.log('From:', from);
          console.log('To:', to);
          console.log('amount:', amount);
          // 이벤트를 수신한 후, 이벤트 수신을 중지
          contract.connect(signer!).off(transferEFilter, transferEListener);
        });

        // 현재 사용자가 NFT 중개인 계약에 NFT를 승인했다는 이벤트 수신
        // let eventFlag = false;
        const approveFilter = contract.connect(signer!).filters.NewApproveToken();
        contract.connect(signer!).on(approveFilter, async (owner, from, to, tokeIds, event) => {
          console.log('Approve Token Event:');
          console.log('Owner:', owner);
          console.log('From:', from);
          console.log('To:', to);
          console.log('tokeIds:', tokeIds);
          setStep(2);
          
          // if(!eventFlag) {
            // API 서버 call하기 - Transfer 결과주기
            // sendData = {
            //   productNo: 2,
            //   assetNo: 9,
            //   tokenIdx: strIds,
            //   purchaseCnt: strIds.length,
            //   state: "P2"
            // };
            // console.log("sendData", sendData);

            // const ret = await axios({
            //   method:'post',
            //   url: API_SERVER + 'purchase',
            //   data: sendData,
            //   headers: {
            //     'Authorization': `Bearer ${ACCESST_TOKEN}`,
            //   },
            // });
            
            // 이벤트 처리기 완료
            // eventFlag = true;
            // setStep(3);
            // console.log("Transfer API called!");
            // 이벤트를 수신한 후, 이벤트 수신을 중지
            contract.connect(signer!).off(approveFilter, approveListener);
          // }
        });

        // 3초 후에 이벤트 수신 중지
        setTimeout(async () => {
          console.log('No Approve Token Event received within 3 seconds.');
          // if(!eventFlag && result.status === 1) {
          if(result.status === 1) {
            // API 서버 call하기 - Transfer 결과주기
            // sendData = {
            //   productNo: 2,
            //   assetNo: 9,
            //   tokenIdx: strIds,
            //   purchaseCnt: strIds.length,
            //   state: "P2"
            // };
            // console.log("sendData", sendData);

            // const ret = await axios({
            //   method:'post',
            //   url: API_SERVER + 'purchase',
            //   data: sendData,
            //   headers: {
            //     'Authorization': `Bearer ${ACCESST_TOKEN}`,
            //   },
            // });
            setStep(2);
            console.log("=== Transfer API called!");
          }
          // 이벤트 처리기 해제
          contract.connect(signer!).off(approveFilter, approveListener);
        }, waitingTime);
        
      }

      // setService({ ...service, tokenIds: tokenIdsArray });
      const balance = await contract.balanceOf(address);
      setService({...service, currentBalance: balance.toNumber(), mintAmount: 0});
    } catch (error) {
      console.log(error);
       alert("NFT 이전 에러 : "+error);
      // // 에러가 발생하면 모든 transaction을 rollback 한다.
      // if(step === 1){
      //   if (contractIntermediary != null) {
      //     let rollbackTListener: any;
      //     // 원래소유자로 다시 MFT 이전 (OK) & 중개인에게 Approve (X)
      //     const txn = await contract.rollbackToken(previousOwner, Ids);
      //     await txn.wait();
      //     const rollbackTFilter = contract.filters.NewRollbackError();
      //     rollbackTListener = contract.on(rollbackTFilter,  async (from, to, tokeIds, event) => {
      //       console.log('rollbackToken Event:');
      //       console.log('From:', from);
      //       console.log('To:', to);
      //       for (let i = 0; i < tokeIds.length; i++) {
      //         console.log('tokeIds:', tokeIds[i].toNumber());
      //       }
      //     // 이벤트를 수신한 후, 이벤트 수신을 중지
      //     contract.connect(signer!).off(rollbackTFilter, rollbackTListener);
      //     });

      //     // 3초 후에 이벤트 수신 중지
      //     setTimeout(() => {
      //       console.log('No Rollback Token Event received within 3 seconds.');
      //       // 이벤트 처리기 해제
      //       contract.connect(signer!).off(rollbackTFilter, rollbackTListener);
      //     }, waitingTime);

      //     // 원래소유자에서 중개자로 다시 approve()해아하는데 불가능.  --- 중개자 모델은 서버에서 해야되네요.
      //   }
      // }else if(step === 2){
      //   // 블록체인을 모두 되돌려? 아님 API Call만 다시 하게하기

      //   // step 1 되돌기기
      //   if (contractIntermediary != null) {
      //     let rollbackTListener: any;
      //     // 원래소유자로 다시 MFT 이전 (OK) & 중개인에게 Approve (X)
      //     const txn = await contract.rollbackToken(previousOwner, Ids);
      //     await txn.wait();
      //     const rollbackTFilter = contract.filters.NewRollbackError();
      //     rollbackTListener = contract.on(rollbackTFilter,  async (from, to, tokeIds, event) => {
      //       console.log('rollbackToken Event:');
      //       console.log('From:', from);
      //       console.log('To:', to);
      //       for (let i = 0; i < tokeIds.length; i++) {
      //         console.log('tokeIds:', tokeIds[i].toNumber());
      //       }
      //     // 이벤트를 수신한 후, 이벤트 수신을 중지
      //     contract.connect(signer!).off(rollbackTFilter, rollbackTListener);
      //     });

      //     // 3초 후에 이벤트 수신 중지
      //     setTimeout(() => {
      //       console.log('No Rollback Token Event received within 3 seconds.');
      //       // 이벤트 처리기 해제
      //       contract.connect(signer!).off(rollbackTFilter, rollbackTListener);
      //     }, waitingTime);

      //     // 원래소유자에서 중개자로 다시 approve()해아하는데 불가능.  --- 중개인 모델은 서버에서 해야되네요.
      //   }
      //   // step 2 되돌기기 (이더를 판매자에서 구매자에게 보내기) 
      //   if (contract != null) {
      //     let rollbackEListener: any;
      //     const txn = await contract.rollback(recipient, Ids);
      //     await txn.wait();
      //     const rollbackEFilter = contract.filters.NewRollbackEther();
      //     rollbackEListener = contract.on(rollbackEFilter,  async (from, to, amount, event) => {
      //       console.log('RollbackEther Event:');
      //       console.log('From:', from);
      //       console.log('To:', to);
      //       console.log('amount:', amount);

      //       // 이벤트를 수신한 후, 이벤트 수신을 중지
      //       contract.connect(signer!).off(rollbackEFilter, rollbackEListener);
      //     });

      //     // 3초 후에 이벤트 수신 중지
      //     setTimeout(() => {
      //       console.log('No Rollback Ether Event received within 3 seconds.');
      //       // 이벤트 처리기 해제
      //       contract.connect(signer!).off(rollbackEFilter, rollbackEListener);
      //     }, waitingTime);

      //     // 이더를 계약에서 구매자에게 보내기 불가능.  --- 중개인 모델은 서버에서 해야되네요.
      //     // 판매자 지갑으로 연동을 해야해서.
      //   }
      // } 

    }
  };

  const burnNFTs = async () => {
    try {
      console.log("burn NFTs");
      const address = service.account;
      // console.log("============= address : "+address);
      const contract = service.contract!;
      const signer = service.ethProvider!.getSigner();
      // const to = service.toAddress!;
      // const tAmount = service.transferMintAmount!;
      // const ethTValue = price.mul(BigNumber.from(tAmount));
      // console.log("=========== : "+transferIds);
      let Ids: number[] = []; 
      for (let i = 0; i < srcIds.length; i++) {
          // console.log("========= : " + srcIds[i]+ ", type : " + typeof(srcIds[i]));
          const Id = srcIds[i].replace(/,/g, '');
          // console.log("["+Id+"] : "+ ", type : " + typeof(Id)+ ", : "+Number(Id)+ ", 길이 : "+Id.length);
          if(Id.length > 0)
            Ids.push(Number(Id));
      }
      // console.log("======= : "+Ids);
      let txn = await contract.connect(signer!).burnNFTs(Ids);
      await txn.wait();
      const balance = await contract.balanceOf(address);
      // setService({ ...service, tokenIds: tokenIdsArray });
      setService({...service, currentBalance: balance.toNumber(), mintAmount: 0});
    } catch (error) {
      console.log(error);
    }
  };

  const withdraw = async () => {
    try {
      console.log("owner withdraw");
      const contract = service.contract!;
      const provider = service.ethProvider!;
      let txn = await contract.withdraw();
      await txn.wait();
      const ethBalance = ethers.utils.formatEther(await provider!.getBalance(service.account));
      setService({...service, ethBalance: `${ethBalance} ETH`});
    } catch (error) {
      console.log(error);
    }
  };

  // Token IDs 입력값을 처리하는 핸들러
  const handleTokenIdsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;

    // 쉼표로 구분된 문자열을 분할하고, 숫자와 쉼표만 추출
    const tokenIdsArray =  value.split(',')
                                .map(tokenIdStr => tokenIdStr.trim()) // 공백 제거
                                .filter(tokenIdStr => /^\d+$/.test(tokenIdStr)+','); // 숫자와 쉼표만 추출

    setSrcIds(tokenIdsArray);
  };

  React.useEffect(() => {
    connectWallet();
    connectIntermediary();
  },  []);

  const connectIntermediary = async () => {
    try {
      console.log("connect intermediary");

      // 중개시스템의 지갑 연결
      //const privateKey = '0x7c852118294e51e653712a81e05800f419141751be58f605c371e15141b007a6';
      // const privateKey = `${PRIVATE_KEY}`;
      const intermediaryKey = 'f25f47edc4d49f50dc88502d47f061194c9ae89b3bb8931cd72cee222a1953ab';
      const intermediaryWallet = new ethers.Wallet(intermediaryKey);

      // Ethereum 노드에 연결할 제공자 설정
      // const providerUrl = 'http://web3api-aro-dev.avataroad.com:8545'; // local
      const providerUrl = 'https://sepolia.infura.io/v3/4351acfacd554a1a86a20661e02c737d'; // sepolia testNet
      const provider = new ethers.providers.JsonRpcProvider(providerUrl);

      // Ethereum 네트워크 제공자를 사용하여 지갑 연결
      const connectedWallet = intermediaryWallet.connect(provider);

      // 스마트 계약 연결
      const contractInter = NFTCollectible__factory.connect(contractAddress, connectedWallet);
      setContractIntermediary(contractInter);
    } catch (error) {
      console.log(error);
    }
  };

  // React.useEffect(() => {
  //   // // Ethereum 공급자 설정
  //   // const provider = new ethers.JsonRpcProvider(rpcUrl);

  //   // // Ethereum 스마트 계약 인스턴스 생성
  //   // const contract = new ethers.Contract(contractAddress, contractAbi, provider);

  //   // const contract = service.contract!;

  //   console.log('watchEvents');
  //   // Mint, Transfer, Burn 이벤트 감시
  //   const watchEvents = async () => {
  //     // Mint 이벤트 감시
  //     if (connect) {
  //       const mintFilter = connect.filters.NewMintToken();
  //       const transferEFilter = connect.filters.NewTransferEther();
  //       const transferTFilter = connect.filters.NewTransferToken();
  //       // const burnFilter = connect.filters.NewBurnToken();

  //       connect.on(mintFilter, (count, assetNames, createdTime, event) => {
  //         console.log('Mint Event:');
  //         console.log('count:', count.toNumber());
  //         console.log('assetNames:', assetNames);
  //         console.log('createdTime:', createdTime);
  //       });

  //       connect.on(transferEFilter, (from, to, amount, event) => {
  //         console.log('Transfer Ether Event:');
  //         console.log('From:', from);
  //         console.log('To:', to);
  //         console.log('amount:', amount);
  //       });

  //       connect.on(transferTFilter, (from, to, tokeIds, event) => {
  //         console.log('Transfer Token Event:');
  //         console.log('From:', from);
  //         console.log('To:', to);
  //         for (let i = 0; i < tokeIds.length; i++) {
  //           console.log('tokeIds:', tokeIds[i].toNumber());
  //         }
  //         // console.log('tokeIds:', tokeIds);
  //       });
  
  //       // connect.on(burnFilter, (from, amount, event) => {
  //       //   console.log('Burn Event:');
  //       //   console.log('From:', from);
  //       //   console.log('Amount:', amount.toString());
  //       // });

  //     }
  //   };

  //   // 이벤트 감시 시작
  //   watchEvents();

  //   // 컴포넌트가 언마운트될 때 이벤트 감시 정리
  //   return () => {
  //     if (connect) {
  //       connect.removeAllListeners('NewMintToken');
  //       connect.removeAllListeners('NewTransferEther');
  //       connect.removeAllListeners('NewTransferToken');
  //     }
  //   };
  // },  [contractAddress]);
// }, [rpcUrl, contractAddress, contractAbi]);


  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Stack direction="row" spacing={2}>
            <Typography variant="h3" component="div">
              NFT Collection
            </Typography>
            <Avatar alt="logo" src={logo} sx={{ width: 64, height: 64 }} />
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container>
        <Box>
          <Stack direction="row" spacing={2} sx={{ margin: 5 }}>
            <Button variant="contained" onClick={connectWallet}>Connect</Button>
            <Button variant="contained" disabled={!state.contractBaseTokenURI} onClick={loadContractBalance}>Load Contract Balance</Button>
            <Button variant="contained" disabled={!state.contractBaseTokenURI} onClick={loadNFTCollection}>Load NFT Collection</Button>
            <Button variant="contained" disabled={!state.contractBaseTokenURI} onClick={handleOpen}>Mint NFT</Button>
            <Button variant="contained" disabled={!state.contractBaseTokenURI} onClick={loadNFTIDs}>load NFTId</Button>
            <Button variant="contained" disabled={!state.contractBaseTokenURI} onClick={handleOpenApprove}>approve NFT</Button>
            <Button variant="contained" disabled={!state.contractBaseTokenURI} onClick={handleOpenCheck}>approve Check</Button>
            <Button variant="contained" disabled={!state.contractBaseTokenURI} onClick={handleOpenTransfer}>Transfer NFT</Button>
            <Button variant="contained" disabled={!state.contractBaseTokenURI} onClick={handleOpenBurn}>Burn NFT</Button>
            <Button variant="contained" disabled={!state.isOwner} onClick={handleOpenWithdrawal}>Withdraw</Button>
          </Stack>
          <Stack direction="column" spacing={10} sx={{ margin: 5 }}>
            <Stack direction="row" spacing={2} sx={{ margin: 5 }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AccountCircle color={state.iconColor} sx={{ mr: 1, my: 0.5 }} />
                <TextField id="wallet_address" label="Connected Account" sx={{ width: 350 }} variant="standard" value={state.connectedWallet}
                  inputProps={{ readOnly: true, }}
                />
              </Box>
              <TextField id="contract_symbol" label="Contract Symbol" variant="standard" value={state.contractSymbol}
                inputProps={{ readOnly: true, }}
              />
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField id="contract_address" label="Contract Address" sx={{ width: 400 }} variant="standard" value={state.contractAddress}
                  inputProps={{ readOnly: true, }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField id="contract_baseURI" label="Contract Base Token URI" sx={{ width: 500 }} variant="standard" value={state.contractBaseTokenURI}
                  inputProps={{ readOnly: true, }}
                />
              </Box>
            </Stack>
            <div>
                  Contract Balance: {contractBalance.toString()}
            </div>
            {/* <List>
              {nftIds.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText primary={item.toNumber()} />
                </ListItem>
              ))}
            </List> */}
           <div>
              {nftIds.map((tokenId) => (
                <div key={tokenId.toNumber()}>
                  토큰 ID: {tokenId.toNumber()}
                </div>
              ))}
            </div>
            <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
              {nftCollection.map((item) => (
                <ImageListItem key={item}>
                  <img
                    src={`${item}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    loading="lazy"
                  />
                     {/* <p>Item: {item}</p>  */}
                </ImageListItem>
              ))}
            </ImageList>
          </Stack>
        </Box>
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={modalStyle}>
                <Stack spacing={1} sx={{ width: 500 }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="mint_account" label="Account" sx={{ width: 500 }} variant="standard" value={service.account}
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="balance" label="Balance" sx={{ width: 500 }} variant="standard" value={service.currentBalance}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="mint_amount" type="number" label="Mint Amount" sx={{ width: 500 }} variant="standard" value={service.mintAmount}
                      onChange={event => {
                        const { value } = event.target;
                        const amount = parseInt(value);
                        setService({ ...service, mintAmount: amount });
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="toAddress" label="Approve Address" sx={{ width: 500 }} variant="standard" value={service.toAddress}  
                      onChange={event => {
                        const { value } = event.target;
                        setService({ ...service, toAddress: value });
                      }}
                    />
                  </Box>
                  <Stack direction="row" spacing={2} sx={{ margin: 5 }}>
                    <Button variant="outlined" onClick={mintNFTs}>Mint</Button>
                    <Button variant="outlined" onClick={handleClose}>close</Button>
                  </Stack>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </div>
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openApprove}
            onClose={handleCloseApprove}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openApprove}>
              <Box sx={modalStyle}>
                <Stack spacing={1} sx={{ width: 500 }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="mint_account" label="Account" sx={{ width: 500 }} variant="standard" value={service.account}
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                  {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="balance" label="Balance" sx={{ width: 500 }} variant="standard" value={service.currentBalance}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box> */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenCount" label="NFT Token Count" sx={{ width: 500 }} variant="standard" value={nftIds.length}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenIds" label="NFT Token Idx" sx={{ width: 500 }} variant="standard" value={nftIds}
                      type="number[]" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="toAddress" label="Approve Address" sx={{ width: 500 }} variant="standard" value={service.toAddress}  
                      onChange={event => {
                        const { value } = event.target;
                        setService({ ...service, toAddress: value });
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenId" label="Approve Token IDs (comma-separated)" sx={{ width: 500 }} variant="standard"
                      value={srcIds.join(', ')} // 배열을 문자열로 변환하여 표시
                      onChange={handleTokenIdsChange} // 수정된 핸들러 사용
                    />
                  </Box>
                  <Stack direction="row" spacing={2} sx={{ margin: 5 }}>
                    <Button variant="outlined" onClick={approveNFTs}>Approve</Button>
                    <Button variant="outlined" onClick={handleCloseApprove}>close</Button>
                  </Stack>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </div>
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openCheck}
            onClose={handleCloseCheck}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openCheck}>
              <Box sx={modalStyle}>
                <Stack spacing={1} sx={{ width: 500 }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="mint_account" label="Account" sx={{ width: 500 }} variant="standard" value={service.account}
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                  {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="balance" label="Balance" sx={{ width: 500 }} variant="standard" value={service.currentBalance}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box> */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenCount" label="NFT Token Count" sx={{ width: 500 }} variant="standard" value={nftIds.length}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenIds" label="NFT Token Idx" sx={{ width: 500 }} variant="standard" value={nftIds}
                      type="number[]" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="toAddress" label="Approved Addresses" sx={{ width: 500 }} variant="standard" value={srcAddresses}  
                      type="string[]" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenId" label="Approve Token IDs (comma-separated)" sx={{ width: 500 }} variant="standard"
                      value={srcIds.join(', ')} // 배열을 문자열로 변환하여 표시
                      onChange={handleTokenIdsChange} // 수정된 핸들러 사용
                    />
                  </Box>
                  <Stack direction="row" spacing={2} sx={{ margin: 5 }}>
                    <Button variant="outlined" onClick={approvedCheckNFTs}>Approved Check</Button>
                    <Button variant="outlined" onClick={handleCloseCheck}>close</Button>
                  </Stack>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </div>
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openTransfer}
            onClose={handleCloseTransfer}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openTransfer}>
              <Box sx={modalStyle}>
                <Stack spacing={1} sx={{ width: 500 }}>
                  {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="mint_account" label="Account" sx={{ width: 500 }} variant="standard" value={service.account}
                      inputProps={{ readOnly: true }}
                    />
                  </Box> */}
                  {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="balance" label="Balance" sx={{ width: 500 }} variant="standard" value={service.currentBalance}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box> */}
                  {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenCount" label="NFT Token Count" sx={{ width: 500 }} variant="standard" value={nftIds.length}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box> */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenIds" label="NFT Token Idx" sx={{ width: 500 }} variant="standard" value={nftIds}
                      type="number[]" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="toAddress" label="To Address" sx={{ width: 500 }} variant="standard" value={service.toAddress}  
                      onChange={event => {
                        const { value } = event.target;
                        setService({ ...service, toAddress: value });
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenId" label="Transfer Token IDs (comma-separated)" sx={{ width: 500 }} variant="standard"
                      value={srcIds.join(', ')} // 배열을 문자열로 변환하여 표시
                      onChange={handleTokenIdsChange} // 수정된 핸들러 사용
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="price_token" type="number" label="Price Per Token(Eth)" sx={{ width: 500 }} variant="standard" 
                      onChange={event => {
                        const { value } = event.target;
                        // const amount = parseInt(value);
                        if(value.length > 0){
                          if (parseFloat(value) < 0) {
                            alert("값은 0보다 커야 합니다.");
                            event.target.value = "";
                          }
                          setPrice( ethers.utils.parseEther(value) );
                        }
                      }}
                    />
                  </Box>
                   <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="price_token_1" label="Price Per Token(Wei)" sx={{ width: 500 }} variant="standard" value={price}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Stack direction="row" spacing={2} sx={{ margin: 5 }}>
                    <Button variant="outlined" onClick={transferNFTs}>Transfer</Button>
                    <Button variant="outlined" onClick={intermediaryNFTs}>Transfer (intermediary)</Button>
                    <Button variant="outlined" onClick={handleCloseTransfer}>close</Button>
                  </Stack>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </div>
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openBurn}
            onClose={handleCloseBurn}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openBurn}>
              <Box sx={modalStyle}>
                <Stack spacing={1} sx={{ width: 500 }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="mint_account" label="Account" sx={{ width: 500 }} variant="standard" value={service.account}
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                  {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="balance" label="Balance" sx={{ width: 500 }} variant="standard" value={service.currentBalance}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box> */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenCount" label="NFT Token Count" sx={{ width: 500 }} variant="standard" value={nftIds.length}
                      type="number" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenIds" label="NFT Token Idx" sx={{ width: 500 }} variant="standard" value={nftIds}
                      type="number[]" inputProps={{ readOnly: true }}
                    />
                  </Box>
                  {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="toAddress" label="To Address" sx={{ width: 500 }} variant="standard" value={service.toAddress}  
                      onChange={event => {
                        const { value } = event.target;
                        setService({ ...service, toAddress: value });
                      }}
                    />
                  </Box> */}
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="tokenId" label="Burn Token IDs (comma-separated)" sx={{ width: 500 }} variant="standard"
                      value={srcIds.join(', ')} // 배열을 문자열로 변환하여 표시
                      onChange={handleTokenIdsChange} // 수정된 핸들러 사용
                    />
                  </Box>
                  <Stack direction="row" spacing={2} sx={{ margin: 5 }}>
                    <Button variant="outlined" onClick={burnNFTs}>Burn</Button>
                    <Button variant="outlined" onClick={handleCloseBurn}>close</Button>
                  </Stack>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </div>
        <div>
          <Modal
            id="withdrawal_modal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openWithdrawal}
            onClose={handleCloseWithdrawal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openWithdrawal}>
              <Box sx={modalStyle}>
                <Stack spacing={1} sx={{ width: 500 }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="owner_account" label="Owner Account" sx={{ width: 500 }} variant="standard" value={service.account}
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField id="ethbalance" label="ETH Balance" sx={{ width: 500 }} variant="standard" value={service.ethBalance}
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Stack direction="row" spacing={2} sx={{ margin: 5 }}>
                    <Button variant="outlined" onClick={withdraw}>Withdraw</Button>
                    <Button variant="outlined" onClick={handleCloseWithdrawal}>close</Button>
                  </Stack>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default EthersDemo;