import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import EthersDemo from './EthersDemo'
import Web3sDemo from './Web3Demo'
import logo from './metamask.svg';
import './App.css';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
            With cryptocurrencies and blockchain technology, NFTs have become part of this crazy world.            
        </p>
        <Box
          sx={{
            display: 'flex', flexWrap: 'wrap', justifyContent: 'center', typography: 'h3',
            '& > :not(style) + :not(style)': {
              ml: 2,
            },
          }}>
          <h6>1. <Link href="/ethersdemo" underline="none" sx={{ color: '#FFF' }}>
            Web3 NFT Demo - ethers.js
          </Link>
          </h6>
          {/* <h6>&nbsp;</h6>
          <h6>2. <Link href="/web3demo" underline="none" sx={{ color: '#FFF' }}>
            Web3 NFT Demo - web3.js
          </Link>
          </h6> */}
        </Box>
      </header>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ethersdemo" element={<EthersDemo />} />
        {/* <Route path="/web3demo"   element={<Web3sDemo />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
